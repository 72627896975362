<template>
    <div class="filter-options d-flex flex-column justify-content-between">
        <div class="column filteroptions-container p-3">
            <!-- <p>{{ groupedOptions }}</p> -->
            <div
                v-for="(group, index) in groupedOptions"
                :key="index"
                class="filtergroup-container mb-4"
            >
                <div class="filtergroup-header pb-2 mt-2 mb-3">
                    <h5
                        class="filtergroup-headerName"
                        v-if="group[0].DatasetFilterGroup.GroupName"
                    >
                        {{ group[0].DatasetFilterGroup.GroupName }}
                    </h5>
                </div>
                <div class="row filters-container px-3">
                    <div
                        v-for="fdata in group"
                        :key="fdata.DatasetFilterColumnId"
                        class=""
                    >
                        <span v-if="fdata.DatasetFilterGroup != null">
                            <div class="Filter-with-header">
                                <div class="Filter-info-wrapper">
                                    <!-- inputs don't stay open -->
                                    <input
                                        v-if="fdata.DatasetColumn.ColumnAlias"
                                        :class="[
                                            fdata.DatasetFilterGroup
                                                .GroupName != ''
                                                ? 'childInput'
                                                : '',
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ] &&
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ].length > 0
                                                ? 'selectedColumn'
                                                : '',
                                        ]"
                                        type="text"
                                        class="inputbox m-2 search_element"
                                        spellcheck="false"
                                        v-model="
                                            fdata.DatasetColumn.ColumnAlias
                                        "
                                        @click="
                                            showfilter(
                                                fdata.DatasetFilterColumnId,
                                                fdata.DatasetColumn.ColumnName,
                                                fdata.filteredDataType
                                            )
                                        "
                                        autocomplete="off"
                                        readonly
                                    />
                                    <input
                                        v-else
                                        :class="[
                                            fdata.DatasetFilterGroup
                                                .GroupName != ''
                                                ? 'childInput'
                                                : '',
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ] &&
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ].length > 0
                                                ? 'selectedColumn'
                                                : '',
                                        ]"
                                        type="text"
                                        class="inputbox m-2 search_element"
                                        spellcheck="false"
                                        v-model="fdata.DatasetColumn.ColumnName"
                                        @click="
                                            showfilter(
                                                fdata.DatasetFilterColumnId,
                                                fdata.DatasetColumn.ColumnName,
                                                fdata.filteredDataType
                                            )
                                        "
                                        autocomplete="off"
                                        readonly
                                    />

                                    <img
                                        src="../../assets/down-arrow-svgrepo-com.svg"
                                        @click="
                                            showfilter(
                                                fdata.DatasetFilterColumnId,
                                                fdata.DatasetColumn.ColumnName,
                                                fdata.filteredDataType
                                            )
                                        "
                                        class="filterImg"
                                        :class="[
                                            filterSho[
                                                fdata.DatasetFilterColumnId
                                            ] == fdata.DatasetFilterColumnId &&
                                            showDropdown
                                                ? 'clicked'
                                                : '',
                                            fdata.DatasetFilterGroup
                                                .GroupName != ''
                                                ? 'childInput'
                                                : '',
                                        ]"
                                    />

                                    <label
                                        :class="[
                                            fdata.DatasetFilterGroup
                                                .GroupName != ''
                                                ? 'filter-count-label'
                                                : 'filter-count-no-head-label',
                                        ]"
                                        v-if="
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ] &&
                                                status[
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                ].length > 0
                                        "
                                    >
                                        ({{
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ].length
                                        }})
                                    </label>
                                </div>
                            </div>
                            <div
                                class="dataset-box"
                                v-if="
                                    filterSho[fdata.DatasetFilterColumnId] ==
                                        fdata.DatasetFilterColumnId &&
                                        showDropdown
                                "
                                v-clickoutside="hide"
                                :class="
                                    fdata.DatasetFilterGroup.GroupName != ''
                                        ? 'childInput'
                                        : ''
                                "
                            >
                                <div>
                                    <div class="flex-container">
                                        <b-dropdown
                                            id="avlFilterDropdown"
                                            name="avlFilterDropdown"
                                            v-model="avlFilters.avlSelected"
                                            :text="avlFilters.avlSelected"
                                            variant="primary"
                                            class="my-3"
                                        >
                                            <b-dropdown-item
                                                v-for="option in avlFilters.options"
                                                :key="option.value"
                                                :value="option.value"
                                                :hidden="
                                                    option.value ==
                                                        'Checkbox' &&
                                                        fdata.filteredDataType ==
                                                            'date'
                                                "
                                                @click="
                                                    avlFilters.avlSelected =
                                                        option.value
                                                "
                                            >
                                                {{ option.text }}
                                            </b-dropdown-item>
                                        </b-dropdown>
                                        <div class="filter-img-wrapper">
                                            <FilterTypeIcon
                                                :filterType="
                                                    fdata.filteredDataType
                                                "
                                            ></FilterTypeIcon>
                                        </div>
                                    </div>
                                </div>
                                <div v-show="avlFilters.avlSelected == 'Menu'">
                                    <div
                                        v-if="
                                            fdata.filteredDataType == 'string'
                                        "
                                    >
                                        <b-form-select
                                            v-model="avlMenuFilters.avlSelected"
                                            class="avlFilter mt-3"
                                            :options="avlMenuFilters.options"
                                            text-field="text"
                                            value-field="value"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >Select filter
                                                    option</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>

                                        <input
                                            placeholder="Enter the value"
                                            class="avlFilter mt-3"
                                            type="text"
                                            id="typeFilter"
                                            v-model="typeFilter"
                                        />
                                    </div>

                                    <div
                                        v-if="
                                            fdata.filteredDataType == 'number'
                                        "
                                    >
                                        <b-form-select
                                            v-model="
                                                avlMenuIntFilters.avlIntSelected
                                            "
                                            class="avlFilter mt-3"
                                            :options="avlMenuIntFilters.options"
                                            text-field="text"
                                            value-field="value"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >Select filter
                                                    option</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>

                                        <input
                                            placeholder="Enter the value"
                                            class="avlFilter mt-3"
                                            type="number"
                                            id="typeFilterInt"
                                            v-model="typeFilter"
                                        />
                                    </div>

                                    <div
                                        v-if="fdata.filteredDataType == 'date'"
                                    >
                                        <b-form-select
                                            @change="
                                                resetCheckbox(
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )
                                            "
                                            v-model="
                                                avlMenuDateFilters.avlDateSelected
                                            "
                                            class="avlFilter mt-3"
                                            :options="
                                                avlMenuDateFilters.options
                                            "
                                            text-field="text"
                                            value-field="value"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >Select filter
                                                    option</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>

                                        <span
                                            v-show="
                                                avlMenuDateFilters.avlDateSelected ==
                                                    'between'
                                            "
                                        >
                                            <ejs-daterangepicker
                                                :startDate="startVal"
                                                :endDate="endVal"
                                                :created="created"
                                                @open="openDateRange"
                                                @close="closeDateRange"
                                                v-model="betweenDateRange"
                                                :placeholder="waterMark"
                                            ></ejs-daterangepicker>
                                        </span>
                                        <span>
                                            <input
                                                v-show="
                                                    avlMenuDateFilters.avlDateSelected !=
                                                        'between'
                                                "
                                                @change="
                                                    resetCheckbox(
                                                        fdata.DatasetColumn
                                                            .ColumnName
                                                    )
                                                "
                                                class="avlFilter mt-3"
                                                type="date"
                                                id="typeFilterdate"
                                                v-model="typeFilter"
                                            />
                                        </span>
                                    </div>
                                    <div class="dropdownFooter">
                                        <b-button
                                            :ref="
                                                'filterButton' +
                                                    fdata.DatasetFilterColumnId
                                            "
                                            @click="
                                                filterItem(
                                                    fdata.DatasetFilterColumnId,
                                                    fdata.DatasetColumn
                                                        .ColumnName,
                                                    typeFilter,
                                                    fdata.filteredDataType
                                                )
                                            "
                                            rounded
                                            class="fltrBtn e-control e-btn e-lib e-primary e-flat"
                                            >Filter</b-button
                                        >
                                        <b-button
                                            @click="clearItem(column)"
                                            rounded
                                            class="clrBtn e-control e-btn e-lib e-flat"
                                            >Clear</b-button
                                        >
                                    </div>
                                </div>
                                <div v-show="avlFilters.avlSelected != 'Menu'">
                                    <div class="form-group has-search ">
                                        <span class="positionAbsolute"
                                            ><a href="#" class="pl-2 mb-1"
                                                ><img
                                                    src="../../assets/search-outline-grey.svg"/></a
                                        ></span>
                                        <input
                                            type="search"
                                            v-model="searchFilter"
                                            @input="
                                                applyFilterDataset(
                                                    searchFilter,
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )
                                            "
                                            class="form-control searchFilter"
                                            placeholder="Search"
                                        />
                                    </div>

                                    <b-form-group>
                                        <template #label>
                                            <b-form-checkbox
                                                v-model="allSelected"
                                                v-if="listofOption.length > 0"
                                                @change="
                                                    toggleAll(
                                                        fdata.DatasetFilterColumnId,
                                                        fdata.DatasetColumn
                                                            .ColumnName
                                                    )
                                                "
                                            >
                                                All
                                            </b-form-checkbox>
                                        </template>
                                        <b-form-checkbox-group
                                            id="flavors"
                                            @change="
                                                updateCheckall(
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )
                                            "
                                            v-model="
                                                status[
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                ]
                                            "
                                            :options="listofOption"
                                            :text-field="'label'"
                                            :value-field="
                                                fdata.DatasetColumn.ColumnName
                                            "
                                            name="flavors"
                                            class="ml-4"
                                            aria-label="Individual flavours"
                                            stacked
                                        ></b-form-checkbox-group>
                                    </b-form-group>
                                </div>
                                <div class="dropdownFooter">
                                    <b-button
                                        @click="
                                            filterItem(
                                                fdata.DatasetFilterColumnId,
                                                fdata.DatasetColumn.ColumnName,
                                                typeFilter,
                                                fdata.filteredDataType
                                            )
                                        "
                                        rounded
                                        class="fltrBtn e-control e-btn e-lib e-primary e-flat"
                                        >Filter</b-button
                                    >
                                    <b-button
                                        @click="clearItem(column)"
                                        rounded
                                        class="clrBtn e-control e-btn e-lib e-flat"
                                        >Clear</b-button
                                    >
                                    <div v-if="filterLoading">
                                        <div
                                            class="spinner-border loadingIcon"
                                            role="status"
                                        >
                                            <span class="sr-only"
                                                >Loading...</span
                                            >
                                        </div>
                                        <div class="loadingText">
                                            Please wait...
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>
                        <span v-else>
                            <div clasa="Filter-with-no-header flex-container">
                                <div class="Filter-info-wrapper">
                                    <input
                                        v-if="fdata.DatasetColumn.ColumnAlias"
                                        spellcheck="false"
                                        type="text"
                                        :class="
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ] &&
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ].length > 0
                                                ? 'selectedColumn'
                                                : ''
                                        "
                                        class="inputbox m-2 search_element"
                                        v-model="
                                            fdata.DatasetColumn.ColumnAlias
                                        "
                                        @click="
                                            showfilter(
                                                fdata.DatasetFilterColumnId,
                                                fdata.DatasetColumn.ColumnName,
                                                fdata.filteredDataType
                                            )
                                        "
                                        autocomplete="off"
                                        readonly
                                    />
                                    <input
                                        v-else
                                        spellcheck="false"
                                        type="text"
                                        :class="
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ] &&
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ].length > 0
                                                ? 'selectedColumn'
                                                : ''
                                        "
                                        class="inputbox m-2 search_element"
                                        v-model="fdata.DatasetColumn.ColumnName"
                                        @click="
                                            showfilter(
                                                fdata.DatasetFilterColumnId,
                                                fdata.DatasetColumn.ColumnName,
                                                fdata.filteredDataType
                                            )
                                        "
                                        autocomplete="off"
                                        readonly
                                    />
                                    <img
                                        src="../../assets/down-arrow-svgrepo-com.svg"
                                        @click="
                                            showfilter(
                                                fdata.DatasetFilterColumnId,
                                                fdata.DatasetColumn.ColumnName,
                                                fdata.filteredDataType
                                            )
                                        "
                                        class="filterImg"
                                    />
                                    <!-- Throws error: status[fdata.DatasetColumn.ColumnName]
                                        .length -->

                                    <label
                                        class="filter-count-no-head-label"
                                        v-if="
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ] &&
                                                status[
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                ].length > 0
                                        "
                                    >
                                        ({{
                                            status[
                                                fdata.DatasetColumn.ColumnName
                                            ].length
                                        }})
                                    </label>
                                </div>
                            </div>

                            <div
                                class="dataset-box"
                                v-clickoutside="hide"
                                v-if="
                                    filterSho[fdata.DatasetFilterColumnId] ==
                                        fdata.DatasetFilterColumnId &&
                                        showDropdown
                                "
                            >
                                <div class="flex-container">
                                    <b-dropdown
                                        id="avlFilterDropdown"
                                        name="avlFilterDropdown"
                                        v-model="avlFilters.avlSelected"
                                        :text="avlFilters.avlSelected"
                                        variant="primary"
                                        class="my-3"
                                    >
                                        <b-dropdown-item
                                            v-for="option in avlFilters.options"
                                            :key="option.value"
                                            :value="option.value"
                                            @click="
                                                avlFilters.avlSelected =
                                                    option.value
                                            "
                                        >
                                            {{ option.text }}
                                        </b-dropdown-item>
                                    </b-dropdown>
                                    <div class="filter-img-wrapper">
                                        <FilterTypeIcon
                                            :filterType="fdata.filteredDataType"
                                        ></FilterTypeIcon>
                                    </div>
                                </div>

                                <div v-if="avlFilters.avlSelected == 'Menu'">
                                    <div
                                        v-if="
                                            fdata.filteredDataType == 'string'
                                        "
                                    >
                                        <b-form-select
                                            @change="
                                                resetCheckbox(
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )
                                            "
                                            v-model="avlMenuFilters.avlSelected"
                                            class="avlFilter mt-3"
                                            :options="avlMenuFilters.options"
                                            text-field="text"
                                            value-field="value"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >Select filter
                                                    option</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>

                                        <input
                                            @change="
                                                resetCheckbox(
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )
                                            "
                                            placeholder="Enter the value"
                                            class="avlFilter mt-3"
                                            type="text"
                                            id="typeFilter"
                                            v-model="typeFilter"
                                        />
                                    </div>

                                    <div
                                        v-if="
                                            fdata.filteredDataType == 'number'
                                        "
                                    >
                                        <b-form-select
                                            @change="
                                                resetCheckbox(
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )
                                            "
                                            v-model="
                                                avlMenuIntFilters.avlIntSelected
                                            "
                                            class="avlFilter mt-3"
                                            :options="avlMenuIntFilters.options"
                                            text-field="text"
                                            value-field="value"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >Select filter
                                                    option</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>

                                        <input
                                            @change="
                                                resetCheckbox(
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )
                                            "
                                            placeholder="Enter the value"
                                            class="avlFilter mt-3"
                                            type="number"
                                            id="typeFilterInt"
                                            v-model="typeFilter"
                                        />
                                    </div>

                                    <div
                                        v-if="fdata.filteredDataType == 'date'"
                                    >
                                        <b-form-select
                                            @change="
                                                resetCheckbox(
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )
                                            "
                                            v-model="
                                                avlMenuDateFilters.avlDateSelected
                                            "
                                            class="avlFilter mt-3"
                                            :options="
                                                avlMenuDateFilters.options
                                            "
                                            text-field="text"
                                            value-field="value"
                                        >
                                            <template #first>
                                                <b-form-select-option
                                                    :value="null"
                                                    disabled
                                                    >Select filter
                                                    option</b-form-select-option
                                                >
                                            </template>
                                        </b-form-select>

                                        <span
                                            v-show="
                                                avlMenuDateFilters.avlDateSelected ==
                                                    'between'
                                            "
                                        >
                                            <ejs-daterangepicker
                                                :startDate="startVal"
                                                :endDate="endVal"
                                                :created="created"
                                                @open="openDateRange"
                                                @close="closeDateRange"
                                                v-model="betweenDateRange"
                                                :placeholder="waterMark"
                                            ></ejs-daterangepicker>
                                        </span>
                                        <span>
                                            <input
                                                v-show="
                                                    avlMenuDateFilters.avlDateSelected !=
                                                        'between'
                                                "
                                                @change="
                                                    resetCheckbox(
                                                        fdata.DatasetColumn
                                                            .ColumnName
                                                    )
                                                "
                                                class="avlFilter mt-3"
                                                type="date"
                                                id="typeFilterdate"
                                                v-model="typeFilter"
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="form-group has-search">
                                        <span class="positionAbsolute"
                                            ><a href="#" class="pl-2 mb-1"
                                                ><img
                                                    src="../../assets/search-outline-grey.svg"/></a
                                        ></span>
                                        <input
                                            type="search"
                                            v-model="searchFilter"
                                            @input="
                                                applyFilterDataset(
                                                    searchFilter,
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )
                                            "
                                            class="form-control searchFilter"
                                            placeholder="Search"
                                        />
                                    </div>
                                    <b-form-group>
                                        <template #label>
                                            <b-form-checkbox
                                                v-model="allSelected"
                                                v-if="listofOption.length > 0"
                                                @change="
                                                    toggleAll(
                                                        fdata.DatasetFilterColumnId,
                                                        fdata.DatasetColumn
                                                            .ColumnName
                                                    )
                                                "
                                            >
                                                All
                                            </b-form-checkbox>
                                        </template>

                                        <b-form-checkbox-group
                                            id="flavors"
                                            @change="
                                                updateCheckall(
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )
                                            "
                                            v-model="
                                                status[
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                ]
                                            "
                                            :options="listofOption"
                                            :text-field="'label'"
                                            :value-field="
                                                fdata.DatasetColumn.ColumnName
                                            "
                                            name="flavors"
                                            class="ml-4"
                                            aria-label="Individual flavours"
                                            stacked
                                        ></b-form-checkbox-group>
                                    </b-form-group>
                                </div>
                                <div class="dropdownFooter">
                                    <b-button
                                        @click="
                                            filterItem(
                                                fdata.DatasetFilterColumnId,
                                                fdata.DatasetColumn.ColumnName,
                                                typeFilter,
                                                fdata.filteredDataType
                                            )
                                        "
                                        rounded
                                        class="fltrBtn e-control e-btn e-lib e-primary e-flat"
                                        >Filter</b-button
                                    >
                                    <b-button
                                        @click="clearItem(column)"
                                        rounded
                                        class="clrBtn e-control e-btn e-lib e-flat"
                                        >Clear</b-button
                                    >
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="filter-buttons-action p-4">
            <b-button
                rounded
                variant="light"
                class="buttonClear mr-1"
                @click="clearAllFilters"
            >
                Clear selection</b-button
            >
            <b-button
                rounded
                @click="applyingFilters"
                class="buttonSelect ml-1"
            >
                Apply filters</b-button
            >
        </div>
    </div>
</template>
<script>
import { DateRangePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import FilterTypeIcon from '../FilterTypeIcon/FilterTypeIcon.vue';
import Vue from 'vue';
Vue.use(DateRangePickerPlugin);
import { datasetService } from '../../services';
import { mapMutations, mapActions } from 'vuex';

export default {
    name: 'advancedfilters',
    components: { FilterTypeIcon },
    props: {
        datasetId: Number,
        projectIds: Array,
        datasetName: String,
    },

    data() {
        return {
            selectedFilterIndex: null,
            betweenDateRange: null,
            waterMark: 'Select a Range',
            startVal: '',
            endVal: '',
            filterVisible: false,
            fullData: {},
            options: {},
            filterShow: false,
            datasourceId: '',
            tablename: '',
            columnname: '',
            currentSwitchState: false,
            listofOption: [],
            listCheckedOptions: {},
            listCheckOptionsCount: 0,
            dataString: null,
            chkOpt: null,
            legecyProjectArray: [],
            counter: 0,
            filterSho: [],
            filtercheck: [],
            status: [],
            verOption: true,
            disableDocIcon: true,
            disableSearchIcon: true,
            disablePostingIcon: true,
            legecyProjectDescArray: [],
            allSelected: false,
            selected1: [],
            listofOptionFilter: [],
            searchFilter: null,
            hidetableSelect: true,
            showDropdown: true,
            filterArr: {},
            column: null,
            newColumn: null,
            checkFirst: true,
            legacyProjectId: '',
            headerArr: [],
            dropdownActiveType: null,
            typeFilter: null,
            listReset: [],
            filterOpen: false,
            statusFilter: [],
            filterLoading: false,
            someOtherProperty: null,
            availableFilterArr: [],
            selectedFilterVal: false,
            filterClick: false,
            isOpenDateRange: false,
            datatypes: [],
            filterDataToSend: {},
            betselected: false,
            dateRange: {},
            avlFilters: {
                avlSelected: 'null',
                options: [
                    {
                        value: 'Checkbox',
                        text: 'Checkbox',
                    },
                    {
                        value: 'Menu',
                        text: 'Menu',
                    },
                ],
            },
            avlMenuFilters: {
                avlSelected: 'startswith',
                options: [
                    {
                        value: 'startswith',
                        text: 'Starts With',
                    },
                    {
                        value: 'endswith',
                        text: 'Ends With',
                    },
                    {
                        value: 'contains',
                        text: 'Contains',
                    },
                    {
                        value: 'equal',
                        text: 'Equal',
                    },
                    {
                        value: 'notequal',
                        text: 'Not Equal',
                    },
                ],
            },
            avlMenuIntFilters: {
                avlIntSelected: 'equal',
                options: [
                    {
                        value: 'equal',
                        text: 'Equal',
                    },
                    {
                        value: 'greaterthan',
                        text: 'Greater Than',
                    },
                    {
                        value: 'greaterthanorequal',
                        text: 'Greater Than Or Equal',
                    },
                    {
                        value: 'lessthan',
                        text: 'Less Than',
                    },
                    {
                        value: 'lessthanorequal',
                        text: 'Less Than Or Equal',
                    },
                    {
                        value: 'notequal',
                        text: 'Not Equal',
                    },
                ],
            },
            avlMenuDateFilters: {
                avlDateSelected: 'equal',
                options: [
                    {
                        value: 'equal',
                        text: 'Equal',
                    },
                    {
                        value: 'greaterthan',
                        text: 'Greater Than',
                    },
                    {
                        value: 'greaterthanorequal',
                        text: 'Greater Than Or Equal',
                    },
                    {
                        value: 'lessthan',
                        text: 'Less Than',
                    },
                    {
                        value: 'lessthanorequal',
                        text: 'Less Than Or Equal',
                    },
                    {
                        value: 'notequal',
                        text: 'Not Equal',
                    },
                    {
                        value: 'between',
                        text: 'Between',
                    },
                ],
            },
            gridData: {},
            groupedOptions: {},
        };
    },
    created() {
        this.gridData = this.$store.state.datasources.dataGrid;
        this.getDatatypes();
        this.getFilterCols();
    },

    methods: {
        ...mapMutations('common', ['setToastMessage']),

        ...mapActions('gridFilters', {
            setCurrentGridFilter: 'setCurrentGridFilter',
        }),
        created: function() {
            setTimeout(() => {
                this.startVal = this.dateRange[this.column].startDate;
                this.endVal = this.dateRange[this.column].endDate;
            });
        },
        getDatatypes() {
            datasetService.getDataTypes().then((res) => {
                this.datatypes = res.data;
                this.filterOptions(this.datasetId);
            });
        },
        filterOptions(ids) {
            var projectIds = this.projectIds;
            datasetService.getFilterOptions(ids, projectIds).then((res) => {
                this.options = res.data;
                let vm = this;
                var columnIdFilter = {};

                let sortColumns = vm.options.map((item) => {
                    return {
                        SortOrder: item.SortOrder,
                        columnName: item.DatasetColumn.ColumnName,
                    };
                });

                vm.$emit('sortedColumns', sortColumns);
                for (var q = 0; q < vm.options.length; q++) {
                    if (vm.options[q].DatasetColumn.ColumnAlias) {
                        columnIdFilter[
                            vm.options[q].DatasetColumn.ColumnAlias
                        ] = vm.options[q].DatasetColumn.DatasetColumnId;
                    } else {
                        columnIdFilter[vm.options[q].DatasetColumn.ColumnName] =
                            vm.options[q].DatasetColumn.DatasetColumnId;
                    }
                }

                for (var w = 0; w < this.options.length; w++) {
                    for (var d = 0; d < this.datatypes.length; d++) {
                        for (
                            var s = 0;
                            s < this.datatypes[d].Datatypes.length;
                            s++
                        ) {
                            if (
                                this.options[w].DatasetColumn.Datatype ==
                                this.datatypes[d].Datatypes[s].DatatypeName
                            ) {
                                this.options[
                                    w
                                ].filteredDataType = this.datatypes[
                                    d
                                ].DatatypeSetName;
                            }
                        }
                    }
                }

                this.options.sort((a, b) => {
                    let tempA;
                    let tempB;

                    if (
                        a.DatasetFilterGroup &&
                        a.DatasetFilterGroup.GroupName === ''
                    ) {
                        tempA = a.SortOrder;
                    } else {
                        tempA = a.DatasetFilterGroup.SortOrder;
                    }

                    if (
                        b.DatasetFilterGroup &&
                        b.DatasetFilterGroup.GroupName === ''
                    ) {
                        tempB = b.SortOrder;
                    } else {
                        tempB = b.DatasetFilterGroup.SortOrder;
                    }
                    return tempA - tempB;
                });

                for (var i = 0; i < this.options.length; i++) {
                    if (this.options[i].DatasetFilterGroup != null) {
                        if (i == 0) {
                            this.headerArr.push(
                                this.options[i].DatasetFilterGroup.GroupName
                            );
                        } else if (
                            this.options[i].DatasetFilterGroup.GroupName ==
                                this.options[i - 1].DatasetFilterGroup
                                    .GroupName &&
                            this.options[i].DatasetFilterGroup.GroupName != ''
                        ) {
                            this.headerArr.push(undefined);
                        } else if (
                            this.options[i].DatasetFilterGroup.GroupName !=
                                this.options[i - 1].DatasetFilterGroup
                                    .GroupName &&
                            this.options[i].DatasetFilterGroup.GroupName != ''
                        ) {
                            this.headerArr.push(
                                this.options[i].DatasetFilterGroup.GroupName
                            );
                        } else if (
                            this.options[i].DatasetFilterGroup.GroupName == ''
                        ) {
                            this.headerArr.push(null);
                        }
                    }
                }
                this.groupedOptions = this.groupObjectsByGroupName(
                    this.options
                );
            });
        },
        groupObjectsByGroupName(objects) {
            const groups = [];

            for (let i = 0; i < objects.length; i++) {
                const currentObject = objects[i];
                const previousObject = objects[i - 1];

                if (
                    !previousObject ||
                    currentObject.DatasetFilterGroup.GroupName !==
                        previousObject.DatasetFilterGroup.GroupName
                ) {
                    groups.push([currentObject]);
                } else {
                    groups[groups.length - 1].push(currentObject);
                }
            }

            return groups;
        },
        getFilterCols() {
            datasetService.getEditingDataSet(this.datasetId).then((res) => {
                let tempObj = {};
                res.data[0].dtSetColumn.forEach((col) => {
                    tempObj[col.ColumnName] = col.DatasetColumnId;
                });
                this.$emit('columnIds', tempObj);
            });
        },
        showfilter(index, columname, type, args) {
            const showFilter = setTimeout(() => {
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage: 'Request timed out',
                });

                return;
            }, 30000);

            this.selectedFilterIndex = index;
            this.showDropdown = false;

            if (this.filterOpen == true) {
                this.filterLoading = false;
                this.filterOpen = false;
            } else {
                this.filterLoading = true;
                this.showDropdown = true;
            }
            if (type === 'date') {
                this.avlFilters.avlSelected = 'Menu';
            } else {
                this.avlFilters.avlSelected = 'Checkbox';
            }
            this.filterClick = false;
            var varType = false;
            var intType = false;
            var dateType = false;

            if (this.status[columname]) {
                if (this.status[columname].length > 0) {
                    var indexreopen = this.availableFilterArr.findIndex(
                        (x) => x.ColumnName == columname
                    );
                    if (type == 'string') {
                        this.avlMenuFilters.avlSelected = this.availableFilterArr[
                            indexreopen
                        ].filterCondition;
                        varType = true;
                    } else if (type == 'number') {
                        this.avlMenuIntFilters.avlIntSelected = this.availableFilterArr[
                            indexreopen
                        ].filterCondition;
                        intType = true;
                    } else if (type == 'date') {
                        this.avlMenuDateFilters.avlDateSelected = this.availableFilterArr[
                            indexreopen
                        ].filterCondition;
                        dateType = true;
                    }
                    this.typeFilter = this.availableFilterArr[
                        indexreopen
                    ].filterValue;
                    if (
                        varType ||
                        intType ||
                        dateType ||
                        this.typeFilter ||
                        this.status[columname]
                    ) {
                        this.selectedFilterVal = true;
                    }
                    var newtypeOption = this.availableFilterArr[indexreopen]
                        .filterCondition;
                }
            } else {
                this.typeFilter = null;
                this.searchFilter = null;
                this.allSelected = false;
                this.avlMenuFilters.avlSelected = null;
                this.avlMenuIntFilters.avlIntSelected = null;
                this.avlMenuDateFilters.avlDateSelected = null;
            }
            if (this.filtercheck.length == 0) {
                this.filtercheck.push(columname);
                this.filterSho = [];

                this.filterSho[index] = index;
            } else {
                if (this.filtercheck[0] == columname) {
                    this.filterSho = [];
                    this.filtercheck = [];
                } else {
                    this.filterSho = [];
                    this.filterSho[index] = index;
                    this.filtercheck = [];
                    this.filtercheck.push(columname);
                }
            }
            if (this.filterSho.length > 0) {
                this.nameofColumn = columname;

                this.getListforfilter(
                    this.gridData.datasourceId,
                    this.gridData.tablename,
                    this.nameofColumn,
                    this.gridData.legacyProjectId,
                    this.typeFilter,
                    newtypeOption,
                    type
                );
            }
            clearTimeout(showFilter);
            this.$forceUpdate();
        },
        getListforfilter(
            datasourceId,
            tablename,
            columnname,
            legacyProjectId,
            typeFilter,
            newtypeOption,
            type
        ) {
            this.listofOption = [];
            this.column = columnname;
            this.filterOpen = true;
            var projectIds = this.projectIds;
            datasetService
                .filterDatasetLegacy(
                    datasourceId,
                    tablename,
                    columnname,
                    legacyProjectId,
                    projectIds
                )
                .then((res) => {
                    this.listofOption = res.data;
                    this.listofOptionFilter = res.data;
                    this.listofOptionAvlFilter = res.data;
                    this.listReset = res.data;
                    let vm = this;

                    this.listofOption.forEach((e, idx) => {
                        if (e[columnname] == null) {
                            this.listofOption[idx].label = '(No values)';
                        } else if (e[columnname] == '') {
                            this.listofOption[idx].label = '(Blanks)';
                        } else {
                            this.listofOption[idx].label = this.listofOption[
                                idx
                            ][columnname];
                        }
                    });

                    if (vm.selectedFilterVal) {
                        this.filterByValue(typeFilter, newtypeOption, type);
                    }

                    setTimeout(() => {
                        this.formElement = document.querySelectorAll(
                            '.checkbox-2 input'
                        );
                        let vm = this;
                        if (vm.listCheckedOptions[vm.column] != undefined) {
                            for (var i = 0; i < vm.listofOption.length; i++) {
                                if (
                                    vm.listCheckedOptions[vm.column].includes(
                                        vm.listofOption[i][vm.column]
                                    )
                                ) {
                                    vm.listofOption[i].status = true;
                                    vm.formElement[i].checked = true;
                                }
                            }
                            vm.listCheckedOptions[vm.column].length ==
                            vm.listofOption.length
                                ? (vm.allSelected = true)
                                : (vm.allSelected = false);
                        }
                    });
                    this.filterLoading = false;
                })
                .catch((err) => {
                    if (this.dateRange[this.column] == undefined) {
                        this.showDropdown = false;
                        this.setToastMessage({
                            showFailure: true,
                            failureToastMessage: err.response.data.Message,
                        });
                    }
                });
        },
        applyingFilters() {
            this.hide();
            this.showDropdown = false;

            for (let ftr in this.status) {
                for (var y = 0; y < this.availableFilterArr.length; y++) {
                    var index = this.options.findIndex(
                        (x) =>
                            x.DatasetColumn.ColumnName ==
                            this.availableFilterArr[y].ColumnName
                    );
                    if (this.availableFilterArr[y].ColumnName == ftr) {
                        if (
                            this.status[ftr].length ==
                                this.availableFilterArr[y].noSelected &&
                            this.availableFilterArr[y].filterCondition
                        ) {
                            this.availableFilterArr[y].ColumnNameValues = [];
                        } else {
                            this.availableFilterArr[
                                y
                            ].ColumnNameValues = this.status[ftr];
                        }

                        this.availableFilterArr[y].Columnid = this.options[
                            index
                        ].DatasetColumn.DatasetColumnId;
                        this.availableFilterArr[
                            y
                        ].filter = this.gridData.legacyProjectId;
                        this.availableFilterArr[y].filterColName = 'SiteCode';

                        if (
                            this.availableFilterArr[y].filterCondition &&
                            this.availableFilterArr[y].filterValue
                        ) {
                            this.availableFilterArr[
                                y
                            ].filterConditionColName = ftr;
                        } else {
                            this.availableFilterArr[
                                y
                            ].filterConditionColName = null;
                        }
                    }
                }
            }

            var allavailFiltersunFiltered = this.availableFilterArr.map(
                (item) => {
                    if (
                        item.ColumnNameValues.length > 0 ||
                        item.filterValue != null
                    ) {
                        if (
                            item.ColumnNameValues.length > 0 &&
                            item.filterValue != ''
                        ) {
                            return {
                                ColumnName: item.ColumnName,
                                ColumnNameValues: item.ColumnNameValues,
                                Columnid: item.Columnid,
                                filter: item.filter,
                                filterColName: item.filterColName,
                                filterCondition: null,
                                filterConditionColName: null,
                                filterValue: null,
                                dataSetId: this.datasetId,
                                projectId: this.projectIds,
                            };
                        } else {
                            return {
                                ColumnName: item.ColumnName,
                                ColumnNameValues: item.ColumnNameValues,
                                Columnid: item.Columnid,
                                filter: item.filter,
                                filterColName: item.filterColName,
                                filterCondition: item.filterCondition,
                                filterConditionColName:
                                    item.filterConditionColName,
                                filterValue: item.filterValue,
                                dataSetId: this.datasetId,
                                projectId: this.projectIds,
                            };
                        }
                    }
                }
            );
            var allavailFilters = allavailFiltersunFiltered.filter((temp) => {
                return temp != undefined;
            });

            this.filterDataToSend = {};
            this.filterDataToSend['datasetid'] = this.datasetId;
            if (allavailFilters.length > 0) {
                this.filterDataToSend['datastring'] = allavailFilters;
            } else {
                this.filterDataToSend['datastring'] = [
                    {
                        filter: this.gridData.legacyProjectId,
                        filterColName: 'SiteCode',
                        dataSetId: this.datasetId,
                        projectId: this.projectIds,
                    },
                ];
            }
            this.saveFilter();
        },
        hide() {
            if (
                !(
                    this.filterOpen &&
                    this.showDropdown &&
                    this.filterLoading &&
                    this.filterSho.length > 0
                ) &&
                !this.isOpenDateRange
            ) {
                this.showDropdown = false;
                this.filterOpen = false;
                this.filterLoading = false;
                this.filterSho = [];
                this.filtercheck = [];
                let tempRefName = 'filterButton' + this.selectedFilterIndex;

                if (
                    this.selectedFilterIndex != null &&
                    this.$refs[tempRefName] &&
                    this.$refs[tempRefName][0]
                ) {
                    this.$refs[tempRefName][0].click();
                }
            }
        },
        saveFilter() {
            let DatasetFilter = [];
            this.availableFilterArr.forEach((ele) => {
                if (this.status[ele.ColumnName].length > 0) {
                    if (ele.filterValue) {
                        if (ele.filterCondition == 'between') {
                            const splitValues = ele.filterValue.split('/');
                            let temp = {
                                field: ele.ColumnName,
                                operator: 'greaterthanorequal',
                                value: splitValues[0],
                                IsActive: 1,
                                isGridFilter: false,
                                filterType: 'Menu',
                                predicate: 'and',
                            };
                            DatasetFilter.push(temp);
                            temp = {
                                field: ele.ColumnName,
                                operator: 'lessthanorequal',
                                value: splitValues[1],
                                IsActive: 1,
                                isGridFilter: false,
                                filterType: 'Menu',
                                predicate: 'and',
                            };
                            DatasetFilter.push(temp);
                        } else {
                            let temp = {
                                field: ele.ColumnName,
                                operator: ele.filterCondition,
                                value: ele.filterValue,
                                IsActive: 1,
                                isGridFilter: false,
                                filterType: 'Menu',
                                predicate: 'and',
                            };
                            DatasetFilter.push(temp);
                        }
                    } else {
                        ele.ColumnNameValues.forEach((filterValue) => {
                            let temp = {
                                field: ele.ColumnName,
                                operator: 'equal',
                                value: filterValue,
                                IsActive: 1,
                                isGridFilter: false,
                                filterType: 'CheckBox',
                                predicate: 'or',
                            };
                            DatasetFilter.push(temp);
                        });
                    }
                }
            });

            let tempGridFilters = {
                dataSetId: parseInt(this.datasetId),
                projectId: parseInt(this.projectIds),
                selectedFilters: [...DatasetFilter],
            };
            this.setCurrentGridFilter(tempGridFilters);
            this.$emit('navigateToDataSetTab');
        },
        filterItem(index, column, typeFilter, type) {
            const filterItem = setTimeout(() => {
                this.setToastMessage({
                    showFailure: true,
                    failureToastMessage: 'Request timed out',
                });
                return;
            }, 30000);
            this.filterOpen = false;
            if (this.status[column] == undefined) {
                this.status[column] = [];
            }
            if (typeFilter || this.status[column]) {
                this.newColumn = column;
                this.listofOptionAvlFilter = this.listReset;
                var typeOption;
                if (type == 'string') {
                    typeOption = this.avlMenuFilters.avlSelected;
                } else if (type == 'number') {
                    typeOption = this.avlMenuIntFilters.avlIntSelected;
                } else if (type == 'date') {
                    typeOption = this.avlMenuDateFilters.avlDateSelected;
                }
                var columnExists = this.availableFilterArr.some(function(
                    field
                ) {
                    return field.ColumnName === column;
                });
                if (typeOption == 'between') {
                    typeFilter =
                        this.formatDate(this.betweenDateRange[0]) +
                        '/' +
                        this.formatDate(this.betweenDateRange[1]);
                    if (this.dateRange[column] == undefined) {
                        this.dateRange[column] = {};
                    }
                    this.dateRange[column][
                        'startDate'
                    ] = this.betweenDateRange[0];
                    this.dateRange[column][
                        'endDate'
                    ] = this.betweenDateRange[1];
                }
                if (columnExists) {
                    var availableFilterIndex = this.availableFilterArr.findIndex(
                        (x) => x.ColumnName == column
                    );
                    if (typeOption == 'between') {
                        this.availableFilterArr[availableFilterIndex] = {
                            ColumnName: column,
                            filterCondition: typeOption,
                            filterValue: typeFilter,
                            filterBetweenValue: this.betweenDateRange,
                        };
                    } else {
                        this.availableFilterArr[availableFilterIndex] = {
                            ColumnName: column,
                            filterCondition: typeOption,
                            filterValue: typeFilter,
                        };
                    }
                } else {
                    if (typeOption == 'between') {
                        this.availableFilterArr.push({
                            ColumnName: column,
                            filterCondition: typeOption,
                            filterValue: typeFilter,
                            filterBetweenValue: this.betweenDateRange,
                        });
                    } else {
                        this.availableFilterArr.push({
                            ColumnName: column,
                            filterCondition: typeOption,
                            filterValue: typeFilter,
                        });
                    }
                }
                this.filterClick = true;
                this.filterByValue(typeFilter, typeOption, type);
                var filterIndex = this.availableFilterArr.findIndex(
                    (x) => x.ColumnName == column
                );
                this.$forceUpdate();
                this.showDropdown = false;
                this.filterSho = [];
                this.filtercheck = [];
                clearTimeout(filterItem);
            }
        },
        filterByValue(typeFilter, typeOption, type) {
            if (typeOption) {
                if (type == 'date') {
                    var date2 = new Date(typeFilter);
                    var date1;
                    if (typeOption == 'equal') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    var d = item[key];
                                    d = d.split(' ')[0];
                                    date1 = new Date(d);
                                    return date1.getTime() === date2.getTime();
                                }
                            }
                        );
                    } else if (typeOption == 'greaterthan') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    var d = item[key];
                                    d = d.split(' ')[0];
                                    date1 = new Date(d);
                                    return date1.getTime() > date2.getTime();
                                }
                            }
                        );
                    } else if (typeOption == 'greaterthanorequal') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    var d = item[key];
                                    d = d.split(' ')[0];
                                    date1 = new Date(d);
                                    return date1.getTime() >= date2.getTime();
                                }
                            }
                        );
                    } else if (typeOption == 'lessthan') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    var d = item[key];
                                    d = d.split(' ')[0];
                                    date1 = new Date(d);
                                    return date1.getTime() < date2.getTime();
                                }
                            }
                        );
                    } else if (typeOption == 'lessthanorequal') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    var d = item[key];
                                    d = d.split(' ')[0];
                                    date1 = new Date(d);
                                    return date1.getTime() <= date2.getTime();
                                }
                            }
                        );
                    } else if (typeOption == 'notequal') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    var d = item[key];
                                    d = d.split(' ')[0];
                                    date1 = new Date(d);
                                    return date1.getTime() != date2.getTime();
                                }
                            }
                        );
                    } else if (typeOption == 'between') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    var d = item[key];
                                    d = d.split(' ')[0];
                                    date1 = new Date(d);
                                    date2 = new Date(this.betweenDateRange[0]);
                                    if (date1.getTime() > date2.getTime()) {
                                        var date3 = new Date(
                                            this.betweenDateRange[1]
                                        );
                                        return (
                                            date1.getTime() < date3.getTime()
                                        );
                                    }
                                }
                            }
                        );
                    }
                } else {
                    if (typeOption == 'startswith') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    if (item[key]) {
                                        return item[key]
                                            .toLowerCase()
                                            .startsWith(
                                                typeFilter.toLowerCase()
                                            );
                                    }
                                }
                            }
                        );
                    } else if (typeOption == 'endswith') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    if (item[key]) {
                                        return item[key]
                                            .toLowerCase()
                                            .endsWith(typeFilter.toLowerCase());
                                    }
                                }
                            }
                        );
                    } else if (typeOption == 'contains') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    if (item[key]) {
                                        return item[key]
                                            .toLowerCase()
                                            .includes(typeFilter.toLowerCase());
                                    }
                                }
                            }
                        );
                    } else if (typeOption == 'equal') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    if (item[key]) {
                                        return (
                                            item[key].toLowerCase() ==
                                            typeFilter.toLowerCase()
                                        );
                                    }
                                }
                            }
                        );
                    } else if (typeOption == 'notequal') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    if (item[key]) {
                                        return (
                                            item[key].toLowerCase() !=
                                            typeFilter.toLowerCase()
                                        );
                                    }
                                }
                            }
                        );
                    } else if (typeOption == 'greaterthan') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    if (type == 'number') {
                                        return item[key] > typeFilter;
                                    } else {
                                        return (
                                            item[key].toLowerCase() >
                                            typeFilter.toLowerCase()
                                        );
                                    }
                                }
                            }
                        );
                    } else if (typeOption == 'greaterthanorequal') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    if (type == 'number') {
                                        return item[key] >= typeFilter;
                                    } else {
                                        return (
                                            item[key].toLowerCase() >=
                                            typeFilter.toLowerCase()
                                        );
                                    }
                                }
                            }
                        );
                    } else if (typeOption == 'lessthan') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    if (type == 'number') {
                                        return item[key] < typeFilter;
                                    } else {
                                        return (
                                            item[key].toLowerCase() <
                                            typeFilter.toLowerCase()
                                        );
                                    }
                                }
                            }
                        );
                    } else if (typeOption == 'lessthanorequal') {
                        this.listofOption = this.listofOptionAvlFilter.filter(
                            (item) => {
                                for (var key in item) {
                                    if (type == 'number') {
                                        return item[key] <= typeFilter;
                                    } else {
                                        return (
                                            item[key].toLowerCase() <=
                                            typeFilter.toLowerCase()
                                        );
                                    }
                                }
                            }
                        );
                    }
                }

                this.listofOptionFilter = this.listofOption;
                if (this.filterClick) {
                    if (type == 'number') {
                        if (!this.status[this.newColumn]) {
                            this.status[this.newColumn] = [];
                        } else {
                            this.status[this.newColumn] = [];
                            for (let p in this.listofOption) {
                                this.status[this.newColumn].push(
                                    this.listofOption[p][this.newColumn]
                                );
                            }
                        }
                    }

                    if (type == 'date') {
                        this.status[this.newColumn] = [];
                        for (let p in this.listofOption) {
                            this.status[this.newColumn].push(
                                this.listofOption[p][this.newColumn]
                            );
                        }
                    } else {
                        if (this.status[this.newColumn].length == 0) {
                            for (let p in this.listofOption) {
                                this.status[this.newColumn].push(
                                    this.listofOption[p][this.newColumn]
                                );
                            }
                        } else {
                            if (this.avlFilters.avlSelected == 'Menu') {
                                this.status[this.newColumn] = [];
                                for (let p in this.listofOption) {
                                    this.status[this.newColumn].push(
                                        this.listofOption[p][this.newColumn]
                                    );
                                }
                            }
                        }
                    }
                    this.allSelected = true;
                }

                this.$forceUpdate();
            }
        },
        applyFilterDataset(searchFilter, columname) {
            if (searchFilter) {
                let vm = this;
                this.column = columname;

                this.listofOption = this.listofOptionFilter.filter((item) => {
                    return item[vm.column]
                        .toLowerCase()
                        .includes(vm.searchFilter.toLowerCase());
                });
            } else {
                this.listofOption = this.listofOptionFilter;
            }
        },
        toggleAll(p, columnnane) {
            this.status[columnnane] = [];
            if (this.allSelected) {
                for (p in this.listofOption) {
                    this.status[columnnane].push(
                        this.listofOption[p][columnnane]
                    );
                }
            }
            this.$forceUpdate();
        },
        openDateRange() {
            this.isOpenDateRange = true;
        },
        closeDateRange() {
            setTimeout(() => {
                this.isOpenDateRange = false;
            });
        },

        resetCheckbox(columnnane) {
            this.status[columnnane] = [];
            if (this.avlMenuDateFilters.avlDateSelected == 'between') {
                this.betselected = true;
            } else {
                this.betselected = false;
            }
            this.$forceUpdate();
        },
        updateCheckall(column) {
            if (this.listofOption.length == this.status[column].length) {
                this.allSelected = true;
            } else {
                this.allSelected = false;
            }
        },
        clearItem(column) {
            var columnExists = this.availableFilterArr.some(function(field) {
                return field.ColumnName === column;
            });
            if (columnExists) {
                var availableFilterIndex = this.availableFilterArr.findIndex(
                    (x) => x.ColumnName == column
                );
                this.availableFilterArr.splice(availableFilterIndex, 1);
            }
            this.status[column] = [];
            this.avlMenuFilters.avlSelected = null;
            this.avlMenuIntFilters.avlIntSelected = null;
            this.avlMenuDateFilters.avlDateSelected = null;
            this.typeFilter = null;
            this.allSelected = false;
            this.listofOption = this.listReset;
            this.$forceUpdate();
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },
        clearAllFilters() {
            this.status = [];
            this.availableFilterArr = [];
            this.$forceUpdate();
        },
    },
    watch: {
        datasetId(newID, oldId) {
            if (newID !== oldId) {
                this.clearAllFilters();
                this.getDatatypes();
                this.filterOptions();
                this.gridData = this.$store.state.datasources.dataGrid;
            }
        },
    },
};
</script>
<style scoped>
.inputbox {
    width: 380px;
    height: 48px;
    border: 1px solid #eaeaea;
    background: #ffffff;
    position: relative;
    color: #515151;
    border-radius: 5px;
}
.search_element {
    /* background-image: url(../../assets/search_dots.svg); */
    background-repeat: no-repeat;
    background-position: right center;
    background-origin: content-box;
    padding: 16px;
    cursor: pointer;
    z-index: 0;
}
.dots {
    position: absolute;
    right: -134px;
    top: 18px;
    cursor: pointer;
}

.search_element.selectedColumn + img + span.count {
    position: relative;
    right: 72px;
}
.search_element.childInput.selectedColumn + img + span.count {
    position: relative;
    right: 40px;
}

.searchFilter {
    width: 206px;
    height: 48px;
    background-color: #f3f3f3 !important;
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0.16px;
    color: #626262;
    border: none !important;
    border-radius: 0 !important;
    margin-top: -10px;
}
.has-search img {
    position: relative;
    top: 12px;
}
.dataset-box .has-search .form-control {
    border: none !important;
    background: #fff;
}
.dataset-box {
    background: #fff !important;
    z-index: 101;
    width: 300px;
    resize: both;
    overflow: auto;
}
.filterImg {
    margin-left: -45px;
    padding: 12px 16.5px 25px;
    margin-bottom: 0;
    z-index: 100;
    position: relative;
    vertical-align: middle;
    margin-top: 6px;
    border-top-right-radius: 3px;
    cursor: pointer;
}
/* .filter-options h4 {
    font-size: 18px !important;
} */
.childInput {
    position: relative;
    /* left: 30px; */
}
.avlFilter {
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    width: 100%;
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    font: normal normal normal 14px/18px IBM Plex Sans;
    letter-spacing: 0.16px;
    color: #515151;
}
.dropdownFooter {
    position: absolute;
    bottom: 10px;
}
.fltrBtn {
    font-size: 14px;
    height: 38px;
}
.clrBtn {
    font-size: 14px;
    color: #006395 !important;
    text-transform: capitalize !important;
    height: 38px;
}
.selectedColumn {
    border: 1px solid rgb(98, 98, 98);
    border-radius: 3px;
}

.my-3 {
    width: 85%;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.filter-count-label {
    margin-left: -67px !important;
    position: absolute;
    margin-top: 19px;
}

.filter-count-no-head-label {
    margin-left: -67px !important;
    position: absolute;
    margin-top: 19px;
}

.filter-img-wrapper {
    margin-top: 25px;
    margin-left: 10px;
}
.loadingText {
    position: absolute;
    top: -100px;
    left: 70px;
    width: 140px;
}
.loadingIcon {
    position: absolute;
    top: -150px;
    left: 100px;
}
.filter-options .buttonSelect {
    top: auto;
    left: auto;
}
.Filter-info-wrapper input:has(+ .clicked) {
    border: 1px solid rgba(98, 98, 98, 50%);
}
.filter-buttons-action {
    left: 0;
}
.filter-buttons-action .buttonClear {
    padding: 11px 10px !important;
    border-radius: 3px;
    border: 0px !important;
    min-width: 135px;
    height: 48px;
}

.filter-options {
    height: calc(100vh - 186px);
}

.row {
    display: flex;
    flex-wrap: wrap;
}

.filter-fields {
    gap: 3px;
}

.filtergroup-header {
    border-bottom: 2px solid #f2f2f2;
}
.filteroptions-container {
    overflow-y: scroll;
    overflow-x: hidden;
}
.filtergroup-container:first-child > :not(:has(.filtergroup-headerName)) {
    border: none !important;
}
</style>
