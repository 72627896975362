var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"filter-options d-flex flex-column justify-content-between"},[_c('div',{staticClass:"column filteroptions-container p-3"},_vm._l((_vm.groupedOptions),function(group,index){return _c('div',{key:index,staticClass:"filtergroup-container mb-4"},[_c('div',{staticClass:"filtergroup-header pb-2 mt-2 mb-3"},[(group[0].DatasetFilterGroup.GroupName)?_c('h5',{staticClass:"filtergroup-headerName"},[_vm._v(" "+_vm._s(group[0].DatasetFilterGroup.GroupName)+" ")]):_vm._e()]),_c('div',{staticClass:"row filters-container px-3"},_vm._l((group),function(fdata){return _c('div',{key:fdata.DatasetFilterColumnId},[(fdata.DatasetFilterGroup != null)?_c('span',[_c('div',{staticClass:"Filter-with-header"},[_c('div',{staticClass:"Filter-info-wrapper"},[(fdata.DatasetColumn.ColumnAlias)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        fdata.DatasetColumn.ColumnAlias
                                    ),expression:"\n                                        fdata.DatasetColumn.ColumnAlias\n                                    "}],staticClass:"inputbox m-2 search_element",class:[
                                        fdata.DatasetFilterGroup
                                            .GroupName != ''
                                            ? 'childInput'
                                            : '',
                                        _vm.status[
                                            fdata.DatasetColumn.ColumnName
                                        ] &&
                                        _vm.status[
                                            fdata.DatasetColumn.ColumnName
                                        ].length > 0
                                            ? 'selectedColumn'
                                            : '' ],attrs:{"type":"text","spellcheck":"false","autocomplete":"off","readonly":""},domProps:{"value":(
                                        fdata.DatasetColumn.ColumnAlias
                                    )},on:{"click":function($event){return _vm.showfilter(
                                            fdata.DatasetFilterColumnId,
                                            fdata.DatasetColumn.ColumnName,
                                            fdata.filteredDataType
                                        )},"input":function($event){if($event.target.composing){ return; }_vm.$set(fdata.DatasetColumn, "ColumnAlias", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(fdata.DatasetColumn.ColumnName),expression:"fdata.DatasetColumn.ColumnName"}],staticClass:"inputbox m-2 search_element",class:[
                                        fdata.DatasetFilterGroup
                                            .GroupName != ''
                                            ? 'childInput'
                                            : '',
                                        _vm.status[
                                            fdata.DatasetColumn.ColumnName
                                        ] &&
                                        _vm.status[
                                            fdata.DatasetColumn.ColumnName
                                        ].length > 0
                                            ? 'selectedColumn'
                                            : '' ],attrs:{"type":"text","spellcheck":"false","autocomplete":"off","readonly":""},domProps:{"value":(fdata.DatasetColumn.ColumnName)},on:{"click":function($event){return _vm.showfilter(
                                            fdata.DatasetFilterColumnId,
                                            fdata.DatasetColumn.ColumnName,
                                            fdata.filteredDataType
                                        )},"input":function($event){if($event.target.composing){ return; }_vm.$set(fdata.DatasetColumn, "ColumnName", $event.target.value)}}}),_c('img',{staticClass:"filterImg",class:[
                                        _vm.filterSho[
                                            fdata.DatasetFilterColumnId
                                        ] == fdata.DatasetFilterColumnId &&
                                        _vm.showDropdown
                                            ? 'clicked'
                                            : '',
                                        fdata.DatasetFilterGroup
                                            .GroupName != ''
                                            ? 'childInput'
                                            : '' ],attrs:{"src":require("../../assets/down-arrow-svgrepo-com.svg")},on:{"click":function($event){return _vm.showfilter(
                                            fdata.DatasetFilterColumnId,
                                            fdata.DatasetColumn.ColumnName,
                                            fdata.filteredDataType
                                        )}}}),(
                                        _vm.status[
                                            fdata.DatasetColumn.ColumnName
                                        ] &&
                                            _vm.status[
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            ].length > 0
                                    )?_c('label',{class:[
                                        fdata.DatasetFilterGroup
                                            .GroupName != ''
                                            ? 'filter-count-label'
                                            : 'filter-count-no-head-label' ]},[_vm._v(" ("+_vm._s(_vm.status[ fdata.DatasetColumn.ColumnName ].length)+") ")]):_vm._e()])]),(
                                _vm.filterSho[fdata.DatasetFilterColumnId] ==
                                    fdata.DatasetFilterColumnId &&
                                    _vm.showDropdown
                            )?_c('div',{directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.hide),expression:"hide"}],staticClass:"dataset-box",class:fdata.DatasetFilterGroup.GroupName != ''
                                    ? 'childInput'
                                    : ''},[_c('div',[_c('div',{staticClass:"flex-container"},[_c('b-dropdown',{staticClass:"my-3",attrs:{"id":"avlFilterDropdown","name":"avlFilterDropdown","text":_vm.avlFilters.avlSelected,"variant":"primary"},model:{value:(_vm.avlFilters.avlSelected),callback:function ($$v) {_vm.$set(_vm.avlFilters, "avlSelected", $$v)},expression:"avlFilters.avlSelected"}},_vm._l((_vm.avlFilters.options),function(option){return _c('b-dropdown-item',{key:option.value,attrs:{"value":option.value,"hidden":option.value ==
                                                    'Checkbox' &&
                                                    fdata.filteredDataType ==
                                                        'date'},on:{"click":function($event){_vm.avlFilters.avlSelected =
                                                    option.value}}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),_c('div',{staticClass:"filter-img-wrapper"},[_c('FilterTypeIcon',{attrs:{"filterType":fdata.filteredDataType}})],1)],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.avlFilters.avlSelected == 'Menu'),expression:"avlFilters.avlSelected == 'Menu'"}]},[(
                                        fdata.filteredDataType == 'string'
                                    )?_c('div',[_c('b-form-select',{staticClass:"avlFilter mt-3",attrs:{"options":_vm.avlMenuFilters.options,"text-field":"text","value-field":"value"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select filter option")])]},proxy:true}],null,true),model:{value:(_vm.avlMenuFilters.avlSelected),callback:function ($$v) {_vm.$set(_vm.avlMenuFilters, "avlSelected", $$v)},expression:"avlMenuFilters.avlSelected"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeFilter),expression:"typeFilter"}],staticClass:"avlFilter mt-3",attrs:{"placeholder":"Enter the value","type":"text","id":"typeFilter"},domProps:{"value":(_vm.typeFilter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.typeFilter=$event.target.value}}})],1):_vm._e(),(
                                        fdata.filteredDataType == 'number'
                                    )?_c('div',[_c('b-form-select',{staticClass:"avlFilter mt-3",attrs:{"options":_vm.avlMenuIntFilters.options,"text-field":"text","value-field":"value"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select filter option")])]},proxy:true}],null,true),model:{value:(
                                            _vm.avlMenuIntFilters.avlIntSelected
                                        ),callback:function ($$v) {_vm.$set(_vm.avlMenuIntFilters, "avlIntSelected", $$v)},expression:"\n                                            avlMenuIntFilters.avlIntSelected\n                                        "}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeFilter),expression:"typeFilter"}],staticClass:"avlFilter mt-3",attrs:{"placeholder":"Enter the value","type":"number","id":"typeFilterInt"},domProps:{"value":(_vm.typeFilter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.typeFilter=$event.target.value}}})],1):_vm._e(),(fdata.filteredDataType == 'date')?_c('div',[_c('b-form-select',{staticClass:"avlFilter mt-3",attrs:{"options":_vm.avlMenuDateFilters.options,"text-field":"text","value-field":"value"},on:{"change":function($event){return _vm.resetCheckbox(
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            )}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select filter option")])]},proxy:true}],null,true),model:{value:(
                                            _vm.avlMenuDateFilters.avlDateSelected
                                        ),callback:function ($$v) {_vm.$set(_vm.avlMenuDateFilters, "avlDateSelected", $$v)},expression:"\n                                            avlMenuDateFilters.avlDateSelected\n                                        "}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                            _vm.avlMenuDateFilters.avlDateSelected ==
                                                'between'
                                        ),expression:"\n                                            avlMenuDateFilters.avlDateSelected ==\n                                                'between'\n                                        "}]},[_c('ejs-daterangepicker',{attrs:{"startDate":_vm.startVal,"endDate":_vm.endVal,"created":_vm.created,"placeholder":_vm.waterMark},on:{"open":_vm.openDateRange,"close":_vm.closeDateRange},model:{value:(_vm.betweenDateRange),callback:function ($$v) {_vm.betweenDateRange=$$v},expression:"betweenDateRange"}})],1),_c('span',[_c('input',{directives:[{name:"show",rawName:"v-show",value:(
                                                _vm.avlMenuDateFilters.avlDateSelected !=
                                                    'between'
                                            ),expression:"\n                                                avlMenuDateFilters.avlDateSelected !=\n                                                    'between'\n                                            "},{name:"model",rawName:"v-model",value:(_vm.typeFilter),expression:"typeFilter"}],staticClass:"avlFilter mt-3",attrs:{"type":"date","id":"typeFilterdate"},domProps:{"value":(_vm.typeFilter)},on:{"change":function($event){return _vm.resetCheckbox(
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )},"input":function($event){if($event.target.composing){ return; }_vm.typeFilter=$event.target.value}}})])],1):_vm._e(),_c('div',{staticClass:"dropdownFooter"},[_c('b-button',{ref:'filterButton' +
                                                fdata.DatasetFilterColumnId,refInFor:true,staticClass:"fltrBtn e-control e-btn e-lib e-primary e-flat",attrs:{"rounded":""},on:{"click":function($event){return _vm.filterItem(
                                                fdata.DatasetFilterColumnId,
                                                fdata.DatasetColumn
                                                    .ColumnName,
                                                _vm.typeFilter,
                                                fdata.filteredDataType
                                            )}}},[_vm._v("Filter")]),_c('b-button',{staticClass:"clrBtn e-control e-btn e-lib e-flat",attrs:{"rounded":""},on:{"click":function($event){return _vm.clearItem(_vm.column)}}},[_vm._v("Clear")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.avlFilters.avlSelected != 'Menu'),expression:"avlFilters.avlSelected != 'Menu'"}]},[_c('div',{staticClass:"form-group has-search "},[_vm._m(0,true),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFilter),expression:"searchFilter"}],staticClass:"form-control searchFilter",attrs:{"type":"search","placeholder":"Search"},domProps:{"value":(_vm.searchFilter)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchFilter=$event.target.value},function($event){return _vm.applyFilterDataset(
                                                _vm.searchFilter,
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            )}]}})]),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.listofOption.length > 0)?_c('b-form-checkbox',{on:{"change":function($event){return _vm.toggleAll(
                                                    fdata.DatasetFilterColumnId,
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )}},model:{value:(_vm.allSelected),callback:function ($$v) {_vm.allSelected=$$v},expression:"allSelected"}},[_vm._v(" All ")]):_vm._e()]},proxy:true}],null,true)},[_c('b-form-checkbox-group',{staticClass:"ml-4",attrs:{"id":"flavors","options":_vm.listofOption,"text-field":'label',"value-field":fdata.DatasetColumn.ColumnName,"name":"flavors","aria-label":"Individual flavours","stacked":""},on:{"change":function($event){return _vm.updateCheckall(
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            )}},model:{value:(
                                            _vm.status[
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            ]
                                        ),callback:function ($$v) {_vm.$set(_vm.status, 
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            , $$v)},expression:"\n                                            status[\n                                                fdata.DatasetColumn\n                                                    .ColumnName\n                                            ]\n                                        "}})],1)],1),_c('div',{staticClass:"dropdownFooter"},[_c('b-button',{staticClass:"fltrBtn e-control e-btn e-lib e-primary e-flat",attrs:{"rounded":""},on:{"click":function($event){return _vm.filterItem(
                                            fdata.DatasetFilterColumnId,
                                            fdata.DatasetColumn.ColumnName,
                                            _vm.typeFilter,
                                            fdata.filteredDataType
                                        )}}},[_vm._v("Filter")]),_c('b-button',{staticClass:"clrBtn e-control e-btn e-lib e-flat",attrs:{"rounded":""},on:{"click":function($event){return _vm.clearItem(_vm.column)}}},[_vm._v("Clear")]),(_vm.filterLoading)?_c('div',[_vm._m(1,true),_c('div',{staticClass:"loadingText"},[_vm._v(" Please wait... ")])]):_vm._e()],1)]):_vm._e()]):_c('span',[_c('div',{attrs:{"clasa":"Filter-with-no-header flex-container"}},[_c('div',{staticClass:"Filter-info-wrapper"},[(fdata.DatasetColumn.ColumnAlias)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(
                                        fdata.DatasetColumn.ColumnAlias
                                    ),expression:"\n                                        fdata.DatasetColumn.ColumnAlias\n                                    "}],staticClass:"inputbox m-2 search_element",class:_vm.status[
                                            fdata.DatasetColumn.ColumnName
                                        ] &&
                                        _vm.status[
                                            fdata.DatasetColumn.ColumnName
                                        ].length > 0
                                            ? 'selectedColumn'
                                            : '',attrs:{"spellcheck":"false","type":"text","autocomplete":"off","readonly":""},domProps:{"value":(
                                        fdata.DatasetColumn.ColumnAlias
                                    )},on:{"click":function($event){return _vm.showfilter(
                                            fdata.DatasetFilterColumnId,
                                            fdata.DatasetColumn.ColumnName,
                                            fdata.filteredDataType
                                        )},"input":function($event){if($event.target.composing){ return; }_vm.$set(fdata.DatasetColumn, "ColumnAlias", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(fdata.DatasetColumn.ColumnName),expression:"fdata.DatasetColumn.ColumnName"}],staticClass:"inputbox m-2 search_element",class:_vm.status[
                                            fdata.DatasetColumn.ColumnName
                                        ] &&
                                        _vm.status[
                                            fdata.DatasetColumn.ColumnName
                                        ].length > 0
                                            ? 'selectedColumn'
                                            : '',attrs:{"spellcheck":"false","type":"text","autocomplete":"off","readonly":""},domProps:{"value":(fdata.DatasetColumn.ColumnName)},on:{"click":function($event){return _vm.showfilter(
                                            fdata.DatasetFilterColumnId,
                                            fdata.DatasetColumn.ColumnName,
                                            fdata.filteredDataType
                                        )},"input":function($event){if($event.target.composing){ return; }_vm.$set(fdata.DatasetColumn, "ColumnName", $event.target.value)}}}),_c('img',{staticClass:"filterImg",attrs:{"src":require("../../assets/down-arrow-svgrepo-com.svg")},on:{"click":function($event){return _vm.showfilter(
                                            fdata.DatasetFilterColumnId,
                                            fdata.DatasetColumn.ColumnName,
                                            fdata.filteredDataType
                                        )}}}),(
                                        _vm.status[
                                            fdata.DatasetColumn.ColumnName
                                        ] &&
                                            _vm.status[
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            ].length > 0
                                    )?_c('label',{staticClass:"filter-count-no-head-label"},[_vm._v(" ("+_vm._s(_vm.status[ fdata.DatasetColumn.ColumnName ].length)+") ")]):_vm._e()])]),(
                                _vm.filterSho[fdata.DatasetFilterColumnId] ==
                                    fdata.DatasetFilterColumnId &&
                                    _vm.showDropdown
                            )?_c('div',{directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.hide),expression:"hide"}],staticClass:"dataset-box"},[_c('div',{staticClass:"flex-container"},[_c('b-dropdown',{staticClass:"my-3",attrs:{"id":"avlFilterDropdown","name":"avlFilterDropdown","text":_vm.avlFilters.avlSelected,"variant":"primary"},model:{value:(_vm.avlFilters.avlSelected),callback:function ($$v) {_vm.$set(_vm.avlFilters, "avlSelected", $$v)},expression:"avlFilters.avlSelected"}},_vm._l((_vm.avlFilters.options),function(option){return _c('b-dropdown-item',{key:option.value,attrs:{"value":option.value},on:{"click":function($event){_vm.avlFilters.avlSelected =
                                                option.value}}},[_vm._v(" "+_vm._s(option.text)+" ")])}),1),_c('div',{staticClass:"filter-img-wrapper"},[_c('FilterTypeIcon',{attrs:{"filterType":fdata.filteredDataType}})],1)],1),(_vm.avlFilters.avlSelected == 'Menu')?_c('div',[(
                                        fdata.filteredDataType == 'string'
                                    )?_c('div',[_c('b-form-select',{staticClass:"avlFilter mt-3",attrs:{"options":_vm.avlMenuFilters.options,"text-field":"text","value-field":"value"},on:{"change":function($event){return _vm.resetCheckbox(
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            )}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select filter option")])]},proxy:true}],null,true),model:{value:(_vm.avlMenuFilters.avlSelected),callback:function ($$v) {_vm.$set(_vm.avlMenuFilters, "avlSelected", $$v)},expression:"avlMenuFilters.avlSelected"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeFilter),expression:"typeFilter"}],staticClass:"avlFilter mt-3",attrs:{"placeholder":"Enter the value","type":"text","id":"typeFilter"},domProps:{"value":(_vm.typeFilter)},on:{"change":function($event){return _vm.resetCheckbox(
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            )},"input":function($event){if($event.target.composing){ return; }_vm.typeFilter=$event.target.value}}})],1):_vm._e(),(
                                        fdata.filteredDataType == 'number'
                                    )?_c('div',[_c('b-form-select',{staticClass:"avlFilter mt-3",attrs:{"options":_vm.avlMenuIntFilters.options,"text-field":"text","value-field":"value"},on:{"change":function($event){return _vm.resetCheckbox(
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            )}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select filter option")])]},proxy:true}],null,true),model:{value:(
                                            _vm.avlMenuIntFilters.avlIntSelected
                                        ),callback:function ($$v) {_vm.$set(_vm.avlMenuIntFilters, "avlIntSelected", $$v)},expression:"\n                                            avlMenuIntFilters.avlIntSelected\n                                        "}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeFilter),expression:"typeFilter"}],staticClass:"avlFilter mt-3",attrs:{"placeholder":"Enter the value","type":"number","id":"typeFilterInt"},domProps:{"value":(_vm.typeFilter)},on:{"change":function($event){return _vm.resetCheckbox(
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            )},"input":function($event){if($event.target.composing){ return; }_vm.typeFilter=$event.target.value}}})],1):_vm._e(),(fdata.filteredDataType == 'date')?_c('div',[_c('b-form-select',{staticClass:"avlFilter mt-3",attrs:{"options":_vm.avlMenuDateFilters.options,"text-field":"text","value-field":"value"},on:{"change":function($event){return _vm.resetCheckbox(
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            )}},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Select filter option")])]},proxy:true}],null,true),model:{value:(
                                            _vm.avlMenuDateFilters.avlDateSelected
                                        ),callback:function ($$v) {_vm.$set(_vm.avlMenuDateFilters, "avlDateSelected", $$v)},expression:"\n                                            avlMenuDateFilters.avlDateSelected\n                                        "}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                            _vm.avlMenuDateFilters.avlDateSelected ==
                                                'between'
                                        ),expression:"\n                                            avlMenuDateFilters.avlDateSelected ==\n                                                'between'\n                                        "}]},[_c('ejs-daterangepicker',{attrs:{"startDate":_vm.startVal,"endDate":_vm.endVal,"created":_vm.created,"placeholder":_vm.waterMark},on:{"open":_vm.openDateRange,"close":_vm.closeDateRange},model:{value:(_vm.betweenDateRange),callback:function ($$v) {_vm.betweenDateRange=$$v},expression:"betweenDateRange"}})],1),_c('span',[_c('input',{directives:[{name:"show",rawName:"v-show",value:(
                                                _vm.avlMenuDateFilters.avlDateSelected !=
                                                    'between'
                                            ),expression:"\n                                                avlMenuDateFilters.avlDateSelected !=\n                                                    'between'\n                                            "},{name:"model",rawName:"v-model",value:(_vm.typeFilter),expression:"typeFilter"}],staticClass:"avlFilter mt-3",attrs:{"type":"date","id":"typeFilterdate"},domProps:{"value":(_vm.typeFilter)},on:{"change":function($event){return _vm.resetCheckbox(
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )},"input":function($event){if($event.target.composing){ return; }_vm.typeFilter=$event.target.value}}})])],1):_vm._e()]):_c('div',[_c('div',{staticClass:"form-group has-search"},[_vm._m(2,true),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchFilter),expression:"searchFilter"}],staticClass:"form-control searchFilter",attrs:{"type":"search","placeholder":"Search"},domProps:{"value":(_vm.searchFilter)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchFilter=$event.target.value},function($event){return _vm.applyFilterDataset(
                                                _vm.searchFilter,
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            )}]}})]),_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.listofOption.length > 0)?_c('b-form-checkbox',{on:{"change":function($event){return _vm.toggleAll(
                                                    fdata.DatasetFilterColumnId,
                                                    fdata.DatasetColumn
                                                        .ColumnName
                                                )}},model:{value:(_vm.allSelected),callback:function ($$v) {_vm.allSelected=$$v},expression:"allSelected"}},[_vm._v(" All ")]):_vm._e()]},proxy:true}],null,true)},[_c('b-form-checkbox-group',{staticClass:"ml-4",attrs:{"id":"flavors","options":_vm.listofOption,"text-field":'label',"value-field":fdata.DatasetColumn.ColumnName,"name":"flavors","aria-label":"Individual flavours","stacked":""},on:{"change":function($event){return _vm.updateCheckall(
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            )}},model:{value:(
                                            _vm.status[
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            ]
                                        ),callback:function ($$v) {_vm.$set(_vm.status, 
                                                fdata.DatasetColumn
                                                    .ColumnName
                                            , $$v)},expression:"\n                                            status[\n                                                fdata.DatasetColumn\n                                                    .ColumnName\n                                            ]\n                                        "}})],1)],1),_c('div',{staticClass:"dropdownFooter"},[_c('b-button',{staticClass:"fltrBtn e-control e-btn e-lib e-primary e-flat",attrs:{"rounded":""},on:{"click":function($event){return _vm.filterItem(
                                            fdata.DatasetFilterColumnId,
                                            fdata.DatasetColumn.ColumnName,
                                            _vm.typeFilter,
                                            fdata.filteredDataType
                                        )}}},[_vm._v("Filter")]),_c('b-button',{staticClass:"clrBtn e-control e-btn e-lib e-flat",attrs:{"rounded":""},on:{"click":function($event){return _vm.clearItem(_vm.column)}}},[_vm._v("Clear")])],1)]):_vm._e()])])}),0)])}),0),_c('div',{staticClass:"filter-buttons-action p-4"},[_c('b-button',{staticClass:"buttonClear mr-1",attrs:{"rounded":"","variant":"light"},on:{"click":_vm.clearAllFilters}},[_vm._v(" Clear selection")]),_c('b-button',{staticClass:"buttonSelect ml-1",attrs:{"rounded":""},on:{"click":_vm.applyingFilters}},[_vm._v(" Apply filters")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"positionAbsolute"},[_c('a',{staticClass:"pl-2 mb-1",attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../assets/search-outline-grey.svg")}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border loadingIcon",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"positionAbsolute"},[_c('a',{staticClass:"pl-2 mb-1",attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../assets/search-outline-grey.svg")}})])])}]

export { render, staticRenderFns }